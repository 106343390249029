const breakpoints = ['640px', '768px', '1024px', '1280px'] as any

// aliases
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const theme = {
  colors: {
    primary: '#33547B',
    secondary: '#B361B5',
    tertiary: '#5A8FB6',
    dark: '#5C5C5C',
    light: '#919191',
    thin: '#DDDCDC',
    green: '#6EA668',
    negative: '#D95C5C',
    decoration: '#E4B60F',
    white: '#FFF',
    bg_thin: '#F9F9F9',
    bg_secondary: '#F1DDF1',
    alternative: '#E9831F',
    bg_alternative: '#FFF4EA',
    bg_green: '#E5F8E3',
    fg_green: '#408738',
    bg_negative: '#FBD8D8',
  },
  breakpoints,
}

export type Jinka_Theme = typeof theme
export default theme
